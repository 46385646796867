<template>
    <section class="config-obras-main container-fluid">
        <navbar-admin titulo="Configuración de obra">
            <el-tooltip content="Ir a proyectos" placement="left" effect="light" visible-arrow>
                <div class="wh-42 bg-5d br-2 d-middle-center cr-pointer" @click="goToObras">
                    <i class="icon-robot-industrial text-white f-25" />
                </div>
            </el-tooltip>
        </navbar-admin>
        <tabs :tabs="tabsConfObra" />
        <router-view class="pl-3 pt-3" />
    </section>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    data(){
        return{
            titulo:'',
            tabsConfObra: [
                {
                    titulo:'Calendarios generales',
                    ruta:"configurar.obras.calendarios.generales",
                },
                {
                    titulo:'Calendarios de talleres',
                    ruta:"configurar.obras.calendarios.talleres",
                },
                {
                    titulo:'Carpetas',
                    ruta:"configurar.obras.carpetas",
                },
                {
                    titulo:'Listado de chequeo',
                    ruta:"configurar.obras.lista.chequeo",
                },
                {
                    titulo:'Calificación servicio',
                    ruta:"configurar.obras.calificacion.servicio",
                },
            ],
        }
    },
    mounted(){
        this.getBreadcumbs(['configurar.obras', this.$route.name])
    },
    watch: {
        "$route.name"(val){
            this.getBreadcumbs(['configurar.obras', val])
        }
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs'
        }),
        goToObras(){
            window.location.href = process.env.VUE_APP_URL_FULL_SMART + '/proyectos';
        }
    }
}
</script>

<style>

</style>